<template>
     <a-table
        :columns="columns"
        :data-source="data"
        class="tableborder"
    >
        <template slot="operation">
            <span class="csP" style="color:#7ebae5">回复</span>
        </template>
    </a-table>
</template>
<script>
const columns = [
  {
    title: '课程包名称',
    dataIndex: 'name',
  }, {
    title: '简称',
    dataIndex: 'age',
  }, {
    title: '标题',
    dataIndex: 'wechart',
  }, {
    title: '标签',
    dataIndex: 'status',
  }, {
    title: '讲义',
    dataIndex: 'status1',
  }, {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
];
export default {
    name: 'courseNum',
    data(){
        return{
            columns,
            data:[]
        }
    }
}
</script>