<template>
     <a-modal
        :visible="visible"
        :confirm-loading="confirmLoading"
        :width="modelWidth"
        @cancel="handleCancel"
        destroyOnClose
        :footer="null"
    >
    <div slot="title">
        <div class="z-model-header">
            <div>{{title}}(1)</div>
            <div v-if="type != 0">
                <a-select default-value="0" style="width: 120px" @change="handleChange">
                    <a-select-option value="0">
                        全部
                    </a-select-option>
                    <a-select-option value="1">
                        已解决
                    </a-select-option>
                    <a-select-option value="2">
                        未解决
                    </a-select-option>
                </a-select>
            </div>
        </div>
    </div>
        <div v-if="type == 0">
            <a-tag color="#b5b5b5" class="mb20x">学院1</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院2</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院3</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院4</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院1</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院2</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院3</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院4</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院1</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院2</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院3</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院4</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院1</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院2</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院3</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院4</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院1</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院2</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院3</a-tag>
            <a-tag color="#b5b5b5" class="mb20x">学院4</a-tag>
        </div>
        <div v-if="type == 1 || type == 2">
            <studentList></studentList>
        </div>
        <div v-if="type == 3">
            <subjectNum></subjectNum>
        </div>
        <div v-if="type == 4">
            <courseNum></courseNum>
        </div>
    </a-modal>
</template>
<script>
import studentList from './studentList'
import subjectNum from './subjectNum'
import courseNum from './courseNum'
export default {
    name: 'overview',
    components: { studentList, subjectNum, courseNum },
    props: {
        visible:{
            type: Boolean,
            default: false
        },
        type: {
            type: Number,
        },
        data:{
            type: Object,
            default: ()=>({})
        }
    },
    watch:{
        visible(val){
            if(val){
                this.title = this.items[this.type].title
                this.modelWidth = this.items[this.type].width
            }
        }
    },
    data(){
        return{
            modelWidth: 520,
            items:[
                {title: '今日访客',width: 520},
                {title: '新增学员',width: 855},
                {title: '学员总数',width: 855},
                {title: '科目数量',width: 1210},
                {title: '课程包数量',width: 1500},
            ],
            title: '',
            confirmLoading: false
        }
    },
    methods: {
        handleOk(){

        },
        handleCancel(){
            this.$emit('close')
        },
        handleChange(){

        }
    }
}
</script>