<template>
    <ve-histogram :data="chartdata" :extend="chartExtend" :judge-width="true" :settings="chartSettings" :grid="grid"  :legend="legends" height="340px" :colors="colors"></ve-histogram>
</template>
<script>
export default {
    props:{
        chartdata: {
            type: Object,
			default:()=>{}
        },
    },
    data(){
        this.chartExtend = {
          series: {
            barWidth: 20
          }
        }
        this.grid = {
            top: 50,
            bottom: 30,
            left: 20,
            right: 20,
        }
        this.chartSettings = {
            yAxisName: ['平均停留时间'],
        }
        return{
            colors: ['#667cff'],
            legends:{
                show: false,
            }
            // chartdata:{
            //     columns: ['类型', '数据'],
            //     rows: [
            //         { '类型': '建议上新', '数据': 5 },
            //         { '类型': '建议反馈', '数据': 3 },
            //         { '类型': '课程答疑', '数据': 12 },
            //     ]
            // }
        }
    },
    mounted(){

    }
}
</script>