<template>
    <ve-ring :data="chartdata" :settings="chartSettings" :legend="legends" :height="height" width="300px" :colors="colors"></ve-ring>
</template>
<script>
export default {
    props:{
        chartdata: {
            type: Object,
			default:()=>{}
        },
        lengend: {
            type: Boolean,
            default: true
        },
        colors: {
            type: Array,
            default: ()=>{['#f14057','#70b2e2', '#ffc14e']}
        },
        height: {
            type: String,
            default:'200px'
        }
    },
    data(){
        this.chartSettings = {
            hoverAnimation: false,
            labelLine: {
                show: false
            },
            label: {
                show:false
            },
            offsetY: '100'
        }
        return{
            legends:{
                show:this.lengend,
                bottom: '10px'
            }
            // chartdata:{
            //     columns: ['类型', '数据'],
            //     rows: [
            //         { '类型': '建议上新', '数据': 5 },
            //         { '类型': '建议反馈', '数据': 3 },
            //         { '类型': '课程答疑', '数据': 12 },
            //     ]
            // }
        }
    },
    mounted(){

    }
}
</script>