<template>
    <ve-line :data="chartdata" :settings="chartSettings" :judge-width="true" :grid="grid" height="260px" :colors="colors" :legend-visible="false" :loading="true" ></ve-line>
</template>
<script>
export default {
    props:[ 'yAxisName', 'chartdata' ],
    data(){
        this.grid = {
            top: 30,
            bottom: 10,
            left: 20,
            right: 20,
        }
        return{
            chartSettings : {
                yAxisName: this.yAxisName,
            },
            colors: ['#ffc45a'],
        }
    },
    mounted(){

    }
}
</script>