<template>
     <a-table
        :columns="columns"
        :data-source="data"
        class="tableborder"
    >
        <template slot="operation">
            <span class="csP" style="color:#7ebae5">回复</span>
        </template>
    </a-table>
</template>
<script>
const columns = [
  {
    title: '学生姓名',
    dataIndex: 'name',
  }, {
    title: '电话',
    dataIndex: 'age',
  }, {
    title: '微信号',
    dataIndex: 'wechart',
  }, {
    title: '课程名称',
    dataIndex: 'status',
  }
];
export default {
    name: 'subjectNum',
    data(){
        return{
            columns,
            data:[]
        }
    }
}
</script>